import { graphql, Link, withPrefix } from 'gatsby';
import * as React from 'react';
import { useState } from 'react';

import { A } from '../components/a';
import Footer from '../components/footer';
import Navbar from '../components/navbar';
import Pagination from '../components/pagination';
import { paginate } from '../helpers/pagination';
import { Location, SiteMetadata } from '../models';
import EmailPopup from '../components/email-popup';

const PAGE_SIZE = 15;

interface TagLike {
  name: string;
  count: string;
}

interface MarkdownRemark {
  excerpt: string;
  fields: {
    slug: string;
  };
  frontmatter: {
    date: string;
    title: string;
    description: string;
    author: string;
    categories: string[];
    coverImage?: {
      publicURL: string;
    };
  };
}

interface Props {
  data: {
    site: SiteMetadata;
    allMarkdownRemark: {
      nodes: MarkdownRemark[];
    };
    allTag: {
      nodes: TagLike[];
    };
    allCategory: {
      nodes: TagLike[];
    };
  };
  location: Location;
}

const Tutorials = ({ data, location }: Props) => {
  const [currentPage, setCurrentPage] = useState(1);

  const allPosts = data.allMarkdownRemark.nodes;
  const totalPages = Math.ceil(allPosts.length / PAGE_SIZE);
  const posts = paginate(allPosts, currentPage, PAGE_SIZE);

  return (
    <div id="main_content">
      <Navbar dark={true} />
      <EmailPopup/>

      <section className="page-banner">
        <div className="container">
          <div className="page-title-wrapper">
            <h1 className="page-title">Tutorials</h1>

            <ul className="bradcurmed">
              <li>
                {/* <A rel="noopener noreferrer">Home</A> */}
                <a href={withPrefix("/")} rel="noopener noreferrer">
                  Home
                </a>
              </li>
              <li>Tutorials</li>
            </ul>
          </div>
        </div>

        <svg
          className="circle"
          data-parallax='{"x" : -200}'
          xmlns="http://www.w3.org/2000/svg"
          width="950px"
          height="950px"
        >
          <path
            fillRule="evenodd"
            stroke="rgb(250, 112, 112)"
            strokeWidth="100px"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            opacity="0.051"
            fill="none"
            d="M450.000,50.000 C670.914,50.000 850.000,229.086 850.000,450.000 C850.000,670.914 670.914,850.000 450.000,850.000 C229.086,850.000 50.000,670.914 50.000,450.000 C50.000,229.086 229.086,50.000 450.000,50.000 Z"
          />
        </svg>

        <ul className="animate-ball">
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
        </ul>
      </section>

      <div className="blog-post-archive">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="post-wrapper">
                {posts.map(post => (
                  <PostListItem key={post.fields.slug} post={post} />
                ))}

                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onClick={(p: number) => setCurrentPage(p)}
                />
              </div>
            </div>

            <div className="col-lg-4">
              <div className="sidebar">
                <SidebarCategories categories={data.allCategory.nodes} />
                <SidebarLatestPosts posts={posts.slice(0, 3)} />
                <SidebarTags tags={data.allTag.nodes} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Tutorials;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { feed: { eq: "tutorials" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          author
          categories
          coverImage {
            publicURL
          }
        }
      }
    }
    allTag(
      filter: { feed: { eq: "tutorials" } }
      sort: { fields: [count], order: DESC }
      limit: 7
    ) {
      nodes {
        name
        count
      }
    }
    allCategory(
      filter: { feed: { eq: "tutorials" } }
      sort: { fields: [count], order: DESC }
      limit: 10
    ) {
      nodes {
        name
        count
      }
    }
  }
`;

interface PostListItemProps {
  post: MarkdownRemark;
}

function PostListItem({ post }: PostListItemProps) {
  return (
    <article className="post">
      <div className="blog-content">
        {post.frontmatter.date &&
          post.frontmatter.categories &&
          post.frontmatter.categories.length !== 0 && (
            <ul className="post-meta">
              {post.frontmatter.date && (
                <li>
                  <A>{post.frontmatter.date}</A>
                </li>
              )}
              {post.frontmatter.categories && post.frontmatter.categories.length !== 0 && (
                <li>
                  {post.frontmatter.categories.map(category => (
                    <Link
                      key={category}
                      to={`/tutorials/categories/${encodeURIComponent(category)}`}
                    />
                  ))}
                </li>
              )}
            </ul>
          )}

        <h3 className="entry-title">
          <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
        </h3>

        {post.frontmatter.coverImage && (
          <div className="feature-image">
            <a href="blog-signle.html">
              <img src={withPrefix(post.frontmatter.coverImage.publicURL)} alt="" />
            </a>
          </div>
        )}

        <p>{post.frontmatter.description}</p>

        <Link to={post.fields.slug} className="read-more">
          Read More <i className="ei ei-arrow_right"></i>
        </Link>

        <A className="author no-hover">
          <img src="media/blog/auth2.jpg" alt="author" />
        </A>
      </div>
    </article>
  );
}

interface SidebarLatestPostsProps {
  posts: MarkdownRemark[];
}

function SidebarLatestPosts({ posts }: SidebarLatestPostsProps) {
  return (
    <div id="gp-posts-widget-2" className="widget gp-posts-widget">
      <h2 className="widget-title">Latest Posts</h2>
      <div className="gp-posts-widget-wrapper">
        {posts.map(post => (
          <LatestPostsItem key={post.fields.slug} post={post} />
        ))}
      </div>
    </div>
  );
}

interface LatestPostsItemProps {
  post: MarkdownRemark;
}

function LatestPostsItem({ post }: LatestPostsItemProps) {
  return (
    <div className="post-item">
      <div className="post-widget-info">
        <h5 className="post-widget-title">
          <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
        </h5>
        <span className="post-date">{post.frontmatter.date}</span>
      </div>
    </div>
  );
}

interface SidebarCategoriesProps {
  categories: TagLike[];
}

function checkGettingStartedIndex(cat: any) {
  return cat.name == "Getting Started"
}

function checkAllIndex(cat: any) {
  return cat.name == "All"
}

function SidebarCategories({ categories }: SidebarCategoriesProps) {

  const gettingStartedIndex = categories.findIndex(checkGettingStartedIndex)
  if(gettingStartedIndex > 0) { categories.unshift(categories.splice(gettingStartedIndex, 1)[0]); }
  const allIndex = categories.findIndex(checkAllIndex)
  if(allIndex > 0) { categories.push(categories.splice(allIndex, 1)[0]); }

  return (
    <div id="categories" className="widget widget_categories">
      <h2 className="widget-title">Categories</h2>
      <ul>
        {categories.map(c => (
          <li key={c.name}>
            <Link to={`/tutorials/categories/${c.name}`}>
              {c.name} <span className="count">({c.count})</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

interface SidebarTagsProps {
  tags: TagLike[];
}

function SidebarTags({ tags }: SidebarTagsProps) {
  return (
    <aside id="tags" className="widget widget_tag">
      <h3 className="widget-title">Popular Tags</h3>
      <div className="tagcloud">
        {tags.map(t => (
          <Link key={t.name} to={`/tutorials/tags/${t.name}`}>
            {t.name}
          </Link>
        ))}
      </div>
    </aside>
  );
}
